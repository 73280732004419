<template>
  <main-layout>
    <div class="main">
      <!-- main-header -->
      <div class="main__header header">
        <div class="header__wysiwyg wysiwyg">
          <h2>Company page</h2>
        </div>
        <div class="header__actions">
          <div class="btn-group">
              <router-link :to="{name: 'profile', params: {id: userdata.user_profile.id}}" target="_blank" class="btn btn_tertiary btn_secondary-ico">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M3.75 5.25C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6V14.25C3 14.4489 3.07902 14.6397 3.21967 14.7803C3.36032 14.921 3.55109 15 3.75 15H12C12.1989 15 12.3897 14.921 12.5303 14.7803C12.671 14.6397 12.75 14.4489 12.75 14.25V9.75C12.75 9.33579 13.0858 9 13.5 9C13.9142 9 14.25 9.33579 14.25 9.75V14.25C14.25 14.8467 14.0129 15.419 13.591 15.841C13.169 16.2629 12.5967 16.5 12 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V6C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75H8.25C8.66421 3.75 9 4.08579 9 4.5C9 4.91421 8.66421 5.25 8.25 5.25H3.75Z"
                          fill="#00A961"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V3H11.25C10.8358 3 10.5 2.66421 10.5 2.25Z"
                          fill="#00A961"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M16.2803 1.71967C16.5732 2.01256 16.5732 2.48744 16.2803 2.78033L8.03033 11.0303C7.73744 11.3232 7.26256 11.3232 6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967L15.2197 1.71967C15.5126 1.42678 15.9874 1.42678 16.2803 1.71967Z"
                          fill="#00A961"/>
                </svg>
                <span class="btn__text">View my public profile</span>
              </router-link>

          </div>
        </div>
      </div>

      <div class="main__content">
        <div class="company">
          <Banner v-if="renderComponent" ref="banner" :type="type"></Banner>
          <TeamMembers v-if="renderComponent" :type="type"></TeamMembers>
          <Description v-if="renderComponent" ref="description" :type="type"></Description>
          <Media v-if="renderComponent" :type="type"></Media>
        </div>
      </div>

      <div v-show="type == 'edit'" class="main__footer footer">
          <div class="footer__actions">
              <div class="btn-group">
                  <a href="#" @click.prevent="saveCompany" class="btn btn_primary">Save</a>
                  <a href="#" @click.prevent="cancelSaveCompany" class="btn btn_secondary">Cancel</a>
              </div>
          </div>
      </div>
  </div>
  <ViewfilePopup></ViewfilePopup>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/Main.vue'
import store from '../store'
import Banner from '@/components/company/Banner'
import TeamMembers from '@/components/company/TeamMembers'
import Description from '@/components/company/Description'
import Media from '@/components/company/Media'
import ViewfilePopup from '@/components/popups/Viewfile'

export default {
    name: "CompanyProfileEdit",
    components: {
        MainLayout,
        Banner,
        TeamMembers,
        Description,
        Media,
        ViewfilePopup,
    },
    data() {
        return {
          renderComponent: true,
          type: 'edit'
        }
    },

    methods: {
      async getCompanyInfo() {
        await store.dispatch('GET_MY_COMPANY_INFO');
        if (this.company.companyInfo.current_user_role !== 'administrator') {
          this.type = 'view';
          store.dispatch('GET_COMPANY_INFO', this.company.companyInfo.id);
        }
        store.dispatch('GET_COMPANY_MEDIA', this.company.companyInfo.id);
        store.dispatch('GET_COMPANY_MEMBERS', this.company.companyInfo.id);
      },
      forceRerender() {
        this.renderComponent = false;

        this.$nextTick(() => {
          this.renderComponent = true;
        });
      },
      saveCompany: function() {
        // console.log('SAVE_COMPANY REFS');
        // console.log(this.$refs);
        var data = {};
        data = this.company.companyInfo;
        if(this.company.companyInfo.companybanner){
          data.companybanner = this.company.companyInfo.companybanner.id;
        } else {
          data.companybanner = "";
        }

        if(this.company.companyInfo.companylogo) {
          data.companylogo = this.company.companyInfo.companylogo.id;
        } else {
          data.companylogo = "";
        }

        if(this.$refs.banner.selectedCity) {
          data.companycity = this.$refs.banner.selectedCity;
        } else {
          data.companycity = this.$refs.banner.company.companyInfo.companycity;
        }

        if(this.$refs.banner.selectedCountry) {
          data.companycountry = this.$refs.banner.selectedCountry;
        } else {
          data.companycountry = this.$refs.banner.company.companyInfo.companycountry;
        }

        if(this.$refs.description.companyDescription) {
          data.companydescription = this.$refs.description.companyDescription;
        } else {
          data.companydescription = this.$refs.description.company.companyInfo.companydescription;
        }

        data.id = this.$refs.banner.company.companyInfo.id;

        // console.log(data);
        store.dispatch('SAVE_COMPANY', data);
        store.dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your data has been successfully saved.'});
      },
      cancelSaveCompany: function() {
        store.dispatch('GET_COMPANY_INFO', this.company.companyInfo.id);
      },
    },
    created() {
        this.getCompanyInfo();
    },

    beforeRouteLeave (to, from, next) {
        if(this.popups.confirmNoguards) {
            next();
        }

        var data = {};

        if(this.company.companyInfo.companybanner){
          data.companybanner = this.company.companyInfo.companybanner.id;
        } else {
          data.companybanner = "";
        }

        if(this.company.companyInfo.companylogo){
          data.companylogo = this.company.companyInfo.companylogo.id;
        } else {
          data.companylogo = "";
        }

        if(this.company.companyInfo.companycity){
          data.companycity = this.company.companyInfo.companycity;
        } else {
          data.companycity = "";
        }

        if(this.company.companyInfo.companycountry){
          data.companycountry = this.company.companyInfo.companycountry;
        } else {
          data.companycountry = "";
        }

        if(this.company.companyInfo.companydescription){
          data.companydescription = this.company.companyInfo.companydescription;
        } else {
          data.companydescription = "";
        }

        if(this.company.companyInfo.companyname){
          data.companyname = this.company.companyInfo.companyname;
        } else {
          data.companyname = "";
        }

        data.registration_number = this.company.companyInfo.registration_number;
        data.stage_of_funding = this.company.companyInfo.stage_of_funding;
        data.type_of_investment = this.company.companyInfo.type_of_investment;

        let LeavePopup = false;

        if(this.$refs.banner.selectedCity) {
          if(data.companycity !== this.$refs.banner.selectedCity) {
            data.companycity = this.$refs.banner.selectedCity;
            // console.log('LeavePopup1')
            LeavePopup = true;
          }
        }

        if(this.$refs.banner.selectedCountry){
          if(data.companycountry !== this.$refs.banner.selectedCountry) {
            data.companycountry = this.$refs.banner.selectedCountry;
            // console.log('LeavePopup2')
            LeavePopup = true;
          }
        }

        let selectedIndustries = this.company.regCompanyIndustry.filter(a => a.selected).map(a => a.id);
        let prevIndustries = this.company.companyInfo.industry_labels;

        selectedIndustries.forEach((item) => {
          if (!prevIndustries.includes(item)) LeavePopup = true;
        });

        prevIndustries.forEach((item) => {
          if (!selectedIndustries.includes(item)) LeavePopup = true;
        });


        if (this.company.companyInfo.companytype == 'investment' &&
            this.company.regCompanyInvestment.filter(a => a.selected).map(a => a.id)[0] != this.company.companyInfo.type_of_investment) {
          LeavePopup = true;
        }

        if (this.company.companyInfo.companytype == 'startup' &&
            this.company.regCompanyFunding.filter(a => a.selected).map(a => a.id)[0] != this.company.companyInfo.stage_of_funding) {
          LeavePopup = true;
        }

        if(this.$refs.description.companyDescription) {
          if(data.companydescription !== this.$refs.description.companyDescription) {
            data.companydescription = this.$refs.description.companyDescription;
            // console.log('LeavePopup6')
            LeavePopup = true;
          }
        }

        data.id = this.company.companyInfo.id;

        if(LeavePopup === true) {
            store.dispatch('SHOW_POPUP', {
                popup: 'confirmPopup',
                show: 'Would you like to save the last edits, otherwise the changes will be lost.',
                route: to,
                name: 'COMPANY_PROFILE',
                data: data
            });
            next(false);
            return;
        } else {
            next();
        }
    },
    computed: {
      company: () => store.state.company,
      userdata: () => store.state.user,
      popups: () => store.state.popups,
    }
}
</script>
